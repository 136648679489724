
import { defineComponent } from '@nuxtjs/composition-api';
import { getEsFooterContent, getEsNavBarAccountContent, getEsNavBarGlobalContent } from '@energysage/es-vue-base';
import { analyticsMixin } from '@/mixins/analytics';

export default defineComponent({
    name: 'DefaultLayout',
    mixins: [analyticsMixin],
    computed: {
        footerContent() {
            const footerContent = getEsFooterContent();

            // update home link to root-relative url
            footerContent.home.link = '/';

            /*
            // TODO: wrap this in a condition checking for not-prod via process.env or something
            // so we can check this in without breaking a potential prod deploy

            // update Our Offerings links to root-relative urls
            footerContent.columns[0].links[0].url = '/shop/home-solar';
            footerContent.columns[0].links[1].url = '/shop/community-solar';
            footerContent.columns[0].links[1].newTab = false;
            footerContent.columns[0].links[2].url = '/shop/heat-pumps';
            footerContent.columns[0].links[2].newTab = false;
            */

            return footerContent;
        },
        navAccountContent() {
            return getEsNavBarAccountContent();
        },
        navGlobalContent() {
            const navGlobalContent = getEsNavBarGlobalContent();

            // update home link to root-relative url
            navGlobalContent.home.link = '/';

            // TODO: wrap this in a condition checking for not-prod via process.env or something
            // so we can check this in without breaking a potential prod deploy

            /*
            // update Home Solar product menu links to root-relative urls
            navGlobalContent.products[0].link = '/shop/home-solar/';
            navGlobalContent.products[0].topics[0].link = '/shop/home-solar/';
            navGlobalContent.products[0].topics[1].link = '/shop/community-solar/';
            navGlobalContent.products[0].topics[1].newTab = false;

            // update Community Solar product menun links to root-relative urls
            navGlobalContent.products[1].link = '/shop/community-solar/';
            navGlobalContent.products[1].newTab = false;

            // update Heating & Cooling product menu links to root-relative urls
            navGlobalContent.products[2].link = '/shop/heat-pumps/';
            navGlobalContent.products[2].newTab = false;
            */

            return navGlobalContent;
        },
    },
});
